import React from 'react';

const View = ({ device, t }) => (
  <div className="card mb-0">
    <div className="card-body">
      <div className="col-12 mt-0 pl-0">
        MAC: {device.mac}
      </div>
    </div>
  </div>
);

export default View;
