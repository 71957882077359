import React from 'react';
import { Route } from 'react-router-dom';

import Device from '../types';

const Component = ({ id, list, t }) => (
  <div className="p-3">
    {list.length === 0 && (
      <div className="card">
        <div className="card-body p-0">
          <div className="row">
            <div className="col-12 p-3 text-center">
              {t('devices.list.lbl_empty')}
            </div>
          </div>
        </div>
      </div>
    )}
    {list.map((data, idx) => (
      <div className="row" key={idx}>
        <div className='col pb-3'>
          <Device id={data._id} view="Pill" />
          <Route path={`/devices/${data._id}`}><Device id={id} view="Menu" /></Route>
        </div>
      </div>
    ))}
  </div>
);

export default Component;
