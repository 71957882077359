import React from 'react';
import moment from 'moment';
import { isEmpty } from 'ramda';

import { Form } from '../../../../../../../components';

const COLORS = {
  0: 'text-info',
  1: 'text-success',
  2: 'text-warning',
  3: 'text-danger',
  4: 'text-dark',
};

const View = ({ state, logs, dates, selectedDate, handlers, t }) => (
  <div className="card mb-0">
    <div className="card-body">
      <Form.Progress on={state.pending} error={state.error} />
      <Form.Messages state={state} showSuccess={false} onClose={handlers.onResetState} />
      <div className="col-12 mt-0 pl-0">
        <div className="justify-content-end">
          <div className="row">
            <div className="col-12 log p-1">
              {dates.map(date => (
                <div key={date} className="col-12">
                  <div className="stable-font clickable" onClick={handlers.onDateSelect(date)}>
                    <span className="text-danger">
                      {(date === selectedDate)
                        ? <i className="fa fa-sm fa-caret-left"/>
                        : <i className="fa fa-sm fa-caret-right"/>
                      }
                    </span>
                    <span className="ml-1">{date}</span>
                    {(date === selectedDate) && (
                      <>
                        <span className="mr-1 ml-1"><b>-</b></span>
                        <span className="text-info"><b>({logs.length})</b></span>
                      </>
                    )}
                  </div>
                  {(date === selectedDate) && (!state.pending) && (
                    <>
                      {!logs || isEmpty(logs) ? (
                        <div><label className="text-dark text-sm pl-3">{t('devices.grating.logs.empty_lbl')}</label></div>
                      ) : (logs.map((record, idx) => (
                        <div key={idx} className="ml-3 text-sm">
                          <div>
                            <span className="stable-font"><b>{moment(record.created).format("HH:mm:ss")}</b></span>
                            <span className="mr-1 ml-1"><b>-</b></span>
                            <span className={`font-weight-bold ${COLORS[record.label.severity]}`}>{record.label.text}</span>
                          </div>
                          {/*{(record.label.severity === 4) && <div className="text-dark text-xsm">{record.data}</div>}*/}
                        </div>
                      )))}
                      <hr className="mt-1 mb-1" />
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default View;
