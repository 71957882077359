import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import config from './config';
import language from './language';
import user from './user';
import devices from './devices';

const persistConfig = {
  key: 'app-state',
  storage,
  whitelist: ['config', 'user', 'language', 'devices'],
};

export default persistReducer(persistConfig, combineReducers({
  config,
  language,
  user,
  devices,
}));
