import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty, pathOr } from 'ramda';
import moment from 'moment';

import View from './view';
import { useApiRequest } from '../../../../../../../../utils/hooks';
import * as messenger from '../../../../../../../../utils/messenger';
import { api as endpoints } from '../../../../../../../../utils/api';

const SEVERITY = {
  info: 0,
  success: 1,
  warn: 2,
  danger: 3,
  unknown: 4,
};

const COMMANDS = {
  's:1': { text: 'Variklis įjungtas', severity: SEVERITY.info },
  's:0': { text: 'Variklis išjungtas', severity: SEVERITY.warn },
  'sensor:1': { text: 'AUTO įjungtas', severity: SEVERITY.success },
  'sensor:0': { text: 'AUTO išjungtas', severity: SEVERITY.danger },
  'connected': { text: 'Prietaisas prisijungė', severity: SEVERITY.success },
  'disconnected': { text: 'Prietaisas išsijungė', severity: SEVERITY.danger },
  'UNKNOWN': { text: 'Nežinoma komanda', severity: SEVERITY.unknown },
};

const getLabel = (event) => {
  const data = event.split(';');
  const valid = data.filter(sample => COMMANDS[sample]);
  return (valid.length > 0) ? COMMANDS[valid[0]] : COMMANDS['UNKNOWN'];
};

const updateDeviceLog = (id, list, setList, selectedDate, setSelectedDate) => (message) => {
  const { c: command, ...data } = JSON.parse(message);
  if ((command === 'device_log') && (`${data.deviceId}` === `${id}`)) {
    // new day started?
    if (moment().startOf('day').isAfter(moment(selectedDate, 'YYYY.MM.DD'))) {
      setSelectedDate(moment().format('YYYY.MM.DD'));
      setList([data]);
    } else {
      setList([...list, data]);
    }
  }
};

const Component = ({ id }) => {
  const { t } = useTranslation();

  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY.MM.DD'));
  useEffect(() => {
    setSelectedDate(moment().format('YYYY.MM.DD'));
  }, [id]);

  const currentDate = moment();
  let dates = [currentDate.format('YYYY.MM.DD')];
  for (let i = 0; i < 6; i++) {
    dates.push(currentDate.subtract(1, 'days').format('YYYY.MM.DD'));
  }

  const { state, execute, resetState } = useApiRequest();
  useEffect(() => {
    if (selectedDate !== null) {
      const dateFrom = moment(selectedDate, 'YYYY.MM.DD').startOf('day').toISOString();
      const dateTo = moment(selectedDate, 'YYYY.MM.DD').endOf('day').toISOString();
      execute(endpoints.devices.getLogs(id, dateFrom, dateTo));
    }
  }, [execute, id, selectedDate]);

  const [list, setList] = useState([]);
  useEffect(() => {
    const result = pathOr([], ['data'])(state);
    setList(isEmpty(result) ? [] : result);
  }, [state]);

  const userData = useSelector((state) => pathOr({}, ['user'])(state));
  useEffect(() => {
    if (moment().format('YYYY.MM.DD') === selectedDate) {
      const callback = { key: 'log', fn: updateDeviceLog(id, list, setList, selectedDate, setSelectedDate) };
      messenger.subscribe(`user/${userData.id}`, callback);
      return () => messenger.unsubscribe(`user/${userData.id}`, callback);
    }
  }, [userData.id, id, selectedDate, list]);

  const logs = list.map(item => ({ ...item, label: getLabel(item.data) })).reverse();

  const handlers = {
    onDateSelect: (date) => () => {
      setSelectedDate(date === selectedDate ? null : date);
    },
    onResetState: resetState,
  };

  return <View
    state={state}
    logs={logs}
    dates={dates}
    selectedDate={selectedDate}
    handlers={handlers}
    t={t}
  />;
};

export default Component;
