import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Form from './form';

const Component = () => (
  <div className="mt-3">
    <Switch>
      <Route path="/profile"><Form /></Route>
      <Redirect to="/profile" />
    </Switch>
  </div>
);

export default Component;
