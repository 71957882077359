import React, { useEffect } from 'react';

import View from './view';
import { useApiRequest } from '../../../../../utils/hooks';
import { api as endpoints } from '../../../../../utils/api';

const Component = () => {
  const { state, execute } = useApiRequest();
  useEffect(() => {
    execute(endpoints.users.profile.get()).then();
  }, [execute]);

  return <View data={state.data} />;
};

export default Component;
