import React from 'react';

const Component = ({ level, connected, strength, dark = false }) => {
  const colors = dark ? {
    on: '#ef8157',
    off : '#ccc'
  } : {
    on: '#f8f9fa',
    off : '#b1b3b4'
  };

  if (!level && strength) {
    if (strength > -67) {
      level = 4;
    } else if (strength > -70) {
      level = 3;
    } else if (strength > -80) {
      level = 2;
    } else if (strength > -90) {
      level = 1;
    } else {
      level = 0;
    }
  }
  return (
    <span className="">
      {connected
        ? (
          <svg id="wifi" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="60 60 170 160" width="18px" height="18px">
            <g>
              <path d="M223.6,104.3c-41-41-107.5-41-148.5,0c-2.9,2.9-2.9,7.7,0,10.6c2.9,2.9,7.7,2.9,10.6,0&#10;&#9;&#9;c35.1-35.1,92.1-35.1,127.3,0c2.9,2.9,7.7,2.9,10.6,0C226.5,111.9,226.5,107.2,223.6,104.3z" style={{ fill: (level > 3) ? colors.on : colors.off }}/>
              <path d="M202.4,125.5c-29.3-29.3-76.8-29.3-106.1,0c-2.9,2.9-2.9,7.7,0,10.6c2.9,2.9,7.7,2.9,10.6,0&#10;&#9;&#9;c23.4-23.4,61.4-23.4,84.9,0c2.9,2.9,7.7,2.9,10.6,0C205.3,133.2,205.3,128.4,202.4,125.5z" style={{ fill: (level > 2) ? colors.on : colors.off }}/>
              <path d="M181.2,146.7c-17.6-17.6-46.1-17.6-63.6,0c-2.9,2.9-2.9,7.7,0,10.6c2.9,2.9,7.7,2.9,10.6,0&#10;&#9;&#9;c11.7-11.7,30.7-11.7,42.4,0c2.9,2.9,7.7,2.9,10.6,0C184.1,154.4,184.1,149.6,181.2,146.7z" style={{ fill: (level > 1) ? colors.on : colors.off }}/>
              <path d="M 164.399994 178.5 A 15 15 0 0 1 149.399994 193.5 A 15 15 0 0 1 134.399994 178.5 A 15 15 0 0 1 149.399994 163.5 A 15 15 0 0 1 164.399994 178.5 Z" style={{ fill: (level > 0) ? colors.on : colors.off }}/>
            </g>
          </svg>
        )
        : (
          <svg id="wifi" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="1230 40 50 180" width="18px" height="18px">
            <path d="M1240,167.9c-5.9,5.9-5.9,5.4,0,21.2c5.9,5.9,15.4,5.9,21.2,0c5.9-5.9,5.9-15.4,0-21.2&#10;&#9;C1255.4,162,1245.9,162,1240,167.9z
                   M1315.4,60.7c-2.9-2.9-7.7-2.9-10.6,0l-18.9,18.9c-37-13.2-79.9-5-109.5,24.6&#10;&#9;c-2.9,2.9-2.9,7.7,0,10.6c2.9,2.9,7.7,2.9,10.6,0c23.6-23.6,56.9-31.3,87-23.3l-12.7,12.7c-22.6-3.2-46.3,3.8-63.6,21.2&#10;&#9;c-2.9,2.9-2.9,7.7,0,10.6c2.9,2.9,7.7,2.9,10.6,0c10.8-10.8,24.6-16.6,38.7-17.4l-54,54c-2.9,2.9-2.9,7.7,0,10.6&#10;&#9;c1.5,1.5,3.4,2.2,5.3,2.2c1.9,0,3.8-0.7,5.3-2.2l112-112C1318.4,68.4,1318.4,63.6,1315.4,60.7z M1259.4,134.4l-14.7,14.7&#10;&#9;c9.5-1.9,19.8,0.8,27.1,8.2c2.9,2.9,7.7,2.9,10.6,0c2.9-2.9,2.9-7.7,0-10.6C1275.9,140.2,1267.9,136.1,1259.4,134.4z M1324.9,104.3&#10;&#9;c-6.1-6.1-12.7-11.3-19.8-15.5l-11,11c7.2,4,14,9,20.2,15.2c2.9,2.9,7.7,2.9,10.6,0C1327.8,111.9,1327.8,107.2,1324.9,104.3z&#10;&#9; M1283,110.8l-11.4,11.4c7.8,2.9,15.2,7.5,21.5,13.8c2.9,2.9,7.7,2.9,10.6,0c2.9-2.9,2.9-7.7,0-10.6&#10;&#9;C1297.5,119.3,1290.5,114.4,1283,110.8z"
                  style={{fill: colors.off}}/>
          </svg>
        )
      }
    </span>
  );
};

export default Component;
