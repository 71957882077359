import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import View from './view';
import schema from './schema';
import { useApiRequest } from '../../../../../../../../utils/hooks';
import { api as endpoints } from '../../../../../../../../utils/api';
import { useForm } from '../../../../../../../../utils/hooks/useForm';
import { getDistanceDeviceConfigWithDefaults } from '../../../../../../../../lib/devices/distance';
import { DEVICE_TYPE } from '../../../../../../../../lib/devices';
import { devices } from '../../../../../../../../lib';

const Component = ({ id }) => {
  const dispatch = useDispatch();
  const { config } = useSelector((state) => state?.devices?.data?.[id] || {});

  const form = useForm(getDistanceDeviceConfigWithDefaults(config), schema);
  const { state: apiState, execute: apiExecute, resetState: resetApiState } = useApiRequest();
  useEffect(() => {
    if (apiState.success && apiState.data) {
      dispatch(devices.save.action(apiState.data));
    }
  }, [apiState, dispatch]);


  const handlers = {
    onSubmit: () => apiExecute(endpoints.devices.saveConfig(id, DEVICE_TYPE.DISTANCE), form.toJSON()),
    onReset: resetApiState,
  };

  return <View form={form} apiState={apiState} handlers={handlers} />;
};

export default Component;
