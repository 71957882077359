import React from 'react';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';

import { devices } from '../../../../../lib';
import * as Default from './default';
import * as Switch from './switch';
import * as Grating from './grating';
import * as Distance from './distance';

const DeviceComponent = {
  [devices.constants.TYPES.DEFAULT]: Default,
  [devices.constants.TYPES.SWITCH]: Switch,
  [devices.constants.TYPES.GRATING]: Grating,
  [devices.constants.TYPES.NDDS75_DISTANCE]: Distance,
};

const Device = ({ id, view }) => {
  const allDevices = useSelector((state) => pathOr({}, ['devices', 'data'])(state));
  if (!allDevices[id]) {
    return null;
  }

  const type = allDevices[id].type || devices.constants.TYPES.DEFAULT;
  const Component = DeviceComponent[devices.constants.TYPES[type] ? type : devices.constants.TYPES.DEFAULT];
  const ComponentView = Component[view];
  return <ComponentView {...allDevices[id]} props={allDevices[id]} />;
};

export default Device;
