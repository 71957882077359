import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import View from './view';
import { useApiRequest, useInterval } from '../../../../../../../utils/hooks';
import { api as endpoints } from '../../../../../../../utils/api';

const usePrevious = (value) => {
  const ref = useRef(null);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

const Component = ({ props }) => {
  const { t } = useTranslation();
  const device = props;
  device.status = device.status || {};

  const previousState = usePrevious(device.status);
  const [status, setStatus] = useState({ success: false, error: false, pending: false });
  useInterval(() => setStatus({ success: false, error: true, pending: false }),
    (status.pending) ? 5000 : null);
  useInterval(() => setStatus({ success: false, error: false, pending: false }),
    (status.success || status.error) ? 1000 : null);

  useEffect(() => {
    if (previousState && (previousState.on !== device.status.on)) {
      setStatus({ success: true, error: false, pending: false });
    }
  }, [previousState, device.status.on]);

  const { execute: executeApi } = useApiRequest();
  const [execute, setExecute] = useState(false);
  useEffect(() => {
    if (execute) {
      setExecute(false);
      setStatus({ success: false, error: false, pending: true });
      executeApi(endpoints.devices.execute(device._id), { command: { c: 'toggle'} });
    }
  }, [execute, executeApi, device._id]);

  const handlers = {
    onActivate: (event) => {
      event.preventDefault();
      if (device.status.connected) {
        setExecute(true);
      }
    },
  };

  return <View device={device} status={status} handlers={handlers} t={t} />;
};

export default Component;
