const labels = {
  system: {
    error_500: 'Sistemos klaida. Bandykite vėliau arba kreipkitės į Sistemos Administratorių.',
  },
  auth: {
    login: {
      title: 'Prisijungimas',
      lbl_username: 'Vartotojo vardas',
      lbl_password: 'Slaptažodis',
      btn_submit: 'Prisijungti',
      error_401: 'Neteisingi prisijungimo duomenys.',
    },
  },
  footer: {
    txt: 'Eltrum Systems',
  },
  menu: {
    devices: 'Prietaisai',
    profile: 'Profilis',
  },

  devices: {
    types: {
      device: 'Prietaisas',
      lock: 'Spyna',
      thermo: 'Termometras',
      meteo: 'Orų stotelė',
      gates: 'Vartai',
      engine: 'Variklis',
      switch: 'Jungtukas',
      grating: 'Grotinės',
    },

    list: {
      lbl_empty: 'Nėra priregistruotų prietaisų.'
    },

    lbl_manual_mode: 'Rankinis režimas',
    lbl_stop_mode: 'STOP paspaustas',
    lbl_disconnected: 'Išjungtas prietaisas',

    device: {
      name_lbl: 'Pavadinimas',
    },
    gates: {
      manual_lbl: 'rankinis',
      auto_lbl: 'auto',
      auto_settings_lbl: 'auto nustatymai',
      auto_time_lbl: 'minutės',
    },
    engine: {
      lbl_on: 'Įjungti',
      lbl_off: 'Išjungti',
    },
    lock: {
      key_owner_name_lbl: 'Rakto savininko vardas',
    },
    switch: {
      on: 'Įjungtas',
      off: 'Išjungtas',
      scheduler: {
        title: 'Kalendorius',
        help_lbl: 'Čia galite pasirinkti laikus... TBD',
        add_lbl: 'Pridėti',
        back_lbl: 'Atgal',
        week_day_1_lbl: 'P.',
        week_day_2_lbl: 'A.',
        week_day_3_lbl: 'T.',
        week_day_4_lbl: 'K.',
        week_day_5_lbl: 'Pn.',
        week_day_6_lbl: 'Š.',
        week_day_7_lbl: 'S.',
        add: {
          action_lbl: 'Veiksmas',
          on_lbl: 'įjungti',
          off_lbl: 'išjungti',
          time_lbl: 'Laikas',
          repeat_lbl: 'Kartoti',
          save_btn: 'Išsaugoti',
        },
      },
    },
    thermo: {
      sensors: {
        title: 'Jutikliai',
        empty_lbl: 'Jutikliai nepajungti, arba prietaisas ne ryšio zonoje.',
        help_lbl: 'Sąraše matote naujausius jutiklių duomenis. ' +
          'Paskutiniame stulpelyje galite įjungti arba išjungti atskirus jutiklius. ' +
          'Jei jutiklis nėra stebėjimo terpėje (pvz.: grūduose) ir matuoja aplinkos temperatūrą (pvz.: oro), ' +
          'jo duomenys gali iškreipti temperatūrų vidurkius ir be reikalo siuntinėti pranešimus. ' +
          'Šiuo atveju saugiausia išjungti tokius jutiklius. ' +
          'Žalias mygtukas - jutiklis įjungtas, raudonas - išjungtas. ',
      },
      limits: {
        title: 'Kritinės temperatūrų ribos ir pranešimai',
        help_lbl: 'Žemiau galite sistemai nurodyti savo pasirinktinas kritines temperatūrų ribas. ' +
          'Taip pat galite pasirinkti ar norite gauti pranešimą el. paštu jei riba buvo pasiekta ar peržengta.',
        header1_lbl: 'Temp. °',
        help1_lbl: 'Temperatūros riba.',
        header2_lbl: 'Pokytis °',
        help2_lbl: 'Temperatūrų skirtumo riba: einamos temperatūros ir praeitos dienos temperatūros skirtumas tuo pačiu laiku.',
        header3_lbl: 'Viršutinė riba',
        help3_lbl: 'Maksimali leistina temperatūra ir maksimalus teigiamas pokytis. Pvz.: Temp. °: 25, Pokytis °: 2.5',
        header4_lbl: 'Apatinė riba',
        help4_lbl: 'Minimali leistina temperatūra ir maksimalus neigiamas pokytis. Pvz.: Temp. °: 10, Pokytis °: -2.5',
        help5_lbl: 'Jei reikalingos papildomos galimybės - susisiekite su sistemos administratoriais.',
        placeholder_maxTemp: 'pvz.: 25',
        placeholder_maxDiff: 'pvz.: 2.5',
        placeholder_minTemp: 'pvz.: 10',
        placeholder_minDiff: 'pvz.: -2.5',
        send_notifications_on_lbl: 'Pranešimų siuntimas įjungtas.',
        send_notifications_off_lbl: 'Pranešimų siuntimas išjungtas.',
      },
      freq: {
        title: 'Temperatūros nuskaitymo dažnis',
        help_lbl: 'Žemiau galite pasirinkti temperatūros nuskaitymo dažnį.',
        help1_lbl: 'Pasirinkus tankesnį nei 1 val. dažnį, prietaiso akumuliatorius išsikraus atitinkamai greičiau. ' +
          'Norėdami akumuliatorių krauti rečiau, stenkitės tankesnį nuskaitymo dažnį naudoti tik tada kai tikrai to reikia.',
        help2_lbl: 'Pasirinktas nuskaitymo dažnis pasikeis automatiškai po sekančio temperatūros nuskaitymo. ' +
          'Jeigu norite, kad pakeitimas įsigaliotų nedelsiant - išjunkite ir vėl įjunkite prietaisą.',
        help3_lbl: 'Nuskaitymo dažnis nebus visiškai tikslus, bet labai artimas laiko tarpas. ' +
          'Prietaisui papildomai reikia laiko užsikrauti, nuskaityti jutiklių parodymus ir išsiųsti duomenis į sistemą.',
        value1_lbl: '1 valanda',
        value2_lbl: '30 minučių',
        value3_lbl: '15 minučių',
        value4_lbl: '10 minučių',
        value5_lbl: '5 minutės',
        value6_lbl: '1 minutė',
      },
      graphs: {
        title: 'Temperatūros pokyčio grafikas',
        help_lbl: 'Grafiką galima stumdyti laike su žemiau esančiais mygtukais. ' +
          'Taip pat galima pasirinkti atvaizduojamos ataskaitos laikotarpį.',
        help1_lbl: 'Programėlė kompiuteryje gali turėti daugiau galimybių grafikams valdyti ir atvaizduoti.',
        lbl_month: 'mėn.',
        lbl_week: 'sav.',
        lbl_day: 'dien.',
        lbl_hour: 'val.',
        lbl_min: 'min.',
        lbl_frequency: 'Duomenų dažnis:',
      },
      list: {
        min_lbl: 'min',
        max_lbl: 'max',
        diff_lbl: 'pokytis',
      },
      sensor_list_lbl: 'Jutikliai',
      temp_raw_lbl: 'Temperatūros indikatoriai',
      temp_diff_lbl: 'Temperatūrų skirtumo indikatoriai',
      warning_from_lbl: 'Įspėjimas nuo',
      danger_from_lbl: 'Pavojus nuo',
      save_config_btn: 'Išsaugoti',
      remove_device_btn: 'Ištrinti prietaisą',
      upgrade_device_btn: 'Atnaujinti prietaisą',
      yes_btn: 'Taip',
      no_btn: 'Ne',
      min_lbl: 'min temp.',
      avg_lbl: 'vid temp.',
      max_lbl: 'max temp.',
      diff_lbl: 'max pokytis',
    },
    meteo: {
      graphs: {
        title: 'Temperatūros ir drėgmės pokyčiai',
        temperature_lbl: 'Temperatūra',
        humidity_lbl: 'Drėgmė',
        temperature_short_lbl: 'Temp.',
        humidity_short_lbl: 'Drėg.',
        help_lbl: 'Grafiką galima stumdyti laike su žemiau esančiais mygtukais. ' +
          'Taip pat galima pasirinkti atvaizduojamos ataskaitos laikotarpį.',
        help1_lbl: 'Programėlė kompiuteryje gali turėti daugiau galimybių grafikams valdyti ir atvaizduoti.',
        lbl_month: 'mėn.',
        lbl_week: 'sav.',
        lbl_day: 'dien.',
        lbl_hour: 'val.',
        lbl_min: 'min.',
        lbl_frequency: 'Duomenų dažnis:',
      },
    },
    thermo_x4: {
      min_lbl: 'min',
      max_lbl: 'max',
      avg_lbl: 'vid',
    },
    grating: {
      lbl_manual_mode: 'Rankinis režimas',
      lbl_stop_mode: 'STOP paspaustas',
      lbl_error: 'Klaida!',
      logs: {
        empty_lbl: 'Įvykių sąrašas tuščias',
      },
    },
  },
};

export default labels;
