const labels = {
  system: {
    error_500: 'System Error. Try again later, or contact System Administrator.',
  },
  auth: {
    login: {
      title: 'Login',
      lbl_username: 'Username',
      lbl_password: 'Password',
      btn_submit: 'Login',
      error_401: 'Invalid credentials.',
    },
  },
  footer: {
    txt: 'Eltrum Systems',
  },
  menu: {
    devices: 'Devices',
    profile: 'Profile',
  },

  devices: {
    types: {
      device: 'Device',
      lock: 'Lock',
      thermo: 'Thermometer',
      meteo: 'Weather station',
      gates: 'Gates',
      engine: 'Engine',
      switch: 'Switch',
      grating: 'Grating',
    },

    list: {
      lbl_empty: 'No devices found.'
    },

    lbl_manual_mode: 'Manual mode',
    lbl_stop_mode: 'STOP pressed',
    lbl_disconnected: 'Disconnected',

    device: {
      name_lbl: 'Name',
    },
    gates: {
      manual_lbl: 'manual',
      auto_lbl: 'auto',
      auto_settings_lbl: 'auto settings',
      auto_time_lbl: 'minutes',
    },
    engine: {
      lbl_on: 'On',
      lbl_off: 'Off',
    },
    lock: {
      key_owner_name_lbl: 'Key owner name',
    },
    switch: {
      on: 'On',
      off: 'Off',
      scheduler: {
        title: 'Scheduler',
        help_lbl: 'Čia galite pasirinkti laikus... TBD',
        add_lbl: 'Add',
        back_lbl: 'Back',
        week_day_1_lbl: 'Mon.',
        week_day_2_lbl: 'Tue.',
        week_day_3_lbl: 'Wed.',
        week_day_4_lbl: 'Thu.',
        week_day_5_lbl: 'Fri.',
        week_day_6_lbl: 'Sat.',
        week_day_7_lbl: 'Sun.',
        add: {
          action_lbl: 'Action',
          on_lbl: 'on',
          off_lbl: 'off',
          time_lbl: 'Time',
          repeat_lbl: 'Repeat',
          save_btn: 'Save',
        },
      },
    },
    thermo: {
      sensors: {
        title: 'Sensors',
        empty_lbl: 'Sensors are disconnected, or device is out of reach.',
        help_lbl: 'List with the most recent sensor data. ' +
          'In the right column you can turn on and off separate sensors. ' +
          'If specific sensor is not inside environment under monitoring (e.g.: not inside grains), ' +
          'and is measuring outside temperature (e.g.: air), ' +
          'it\'s data might distort temperature averages, and start sending false notifications. ' +
          'In this case it would be useful to turn off such sensors. ' +
          'Green button - sensor is on, red - off. ',
      },
      limits: {
        title: 'Critical temperature limits and messages',
        help_lbl: 'Below you can specify your choice of critical temperature limits. ' +
          'You can also choose whether you want to receive a notification by mail ' +
          'if the limit has been reached or exceeded.',
        header1_lbl: 'Temp. °',
        help1_lbl: 'Temperature limit.',
        header2_lbl: 'Change °',
        help2_lbl: 'Temperature difference limit: the difference between the current ' +
          'temperature and the previous day\'s temperature at the same time.',
        header3_lbl: 'Upper limit',
        help3_lbl: 'Maximal permissible temperature and maximal positive change. E.g. Temp. °: 25, Change °: 2.5',
        header4_lbl: 'Lower limit',
        help4_lbl: 'Minimal permissible temperature and maximal negative change. E.g. Temp. °: 10, Change °: -2.5',
        help5_lbl: 'Contact system administrators for additional options.',
        placeholder_maxTemp: 'e.g. 25',
        placeholder_maxDiff: 'e.g. 2.5',
        placeholder_minTemp: 'e.g. 10',
        placeholder_minDiff: 'e.g. -2.5',
        send_notifications_on_lbl: 'Notification sending - on.',
        send_notifications_off_lbl: 'Notification sending - off.',
      },
      freq: {
        title: 'Temperature reading frequency',
        help_lbl: 'You can select the temperature scan frequency below.',
        help1_lbl: 'If you select denser frequency than 1 hour, device battery will discharge faster. ' +
          'To charge the battery less frequently, try using denser scan frequency only when you really need it.',
        help2_lbl: 'Selected scan frequency will change automatically after the next temperature scan. ' +
          'If you want the change to take effect immediately, switch the device off and on again.',
        help3_lbl: 'The scan frequency will not be completely accurate, but a very close time frame. ' +
          'Device takes extra time to boot up, read sensors, and send data to the system.',
        value1_lbl: '1 hour',
        value2_lbl: '30 minutes',
        value3_lbl: '15 minutes',
        value4_lbl: '10 minutes',
        value5_lbl: '5 minutes',
        value6_lbl: '1 minute',
      },
      graphs: {
        title: 'Graph of temperature change',
        help_lbl: 'Graph can be shifted in time with the buttons below. ' +
          'You can also select the date range for the report you\'re viewing.',
        help1_lbl: 'App might have more controls of how to display graphics on computer.',
        lbl_month: 'month',
        lbl_week: 'week',
        lbl_day: 'day',
        lbl_hour: 'hour',
        lbl_min: 'min.',
        lbl_frequency: 'Data frequency:',
      },
      list: {
        min_lbl: 'min',
        max_lbl: 'max',
        diff_lbl: 'change',
      },
      sensor_list_lbl: 'Sensors',
      temp_raw_lbl: 'Raw temperature indicators',
      temp_diff_lbl: 'Temperature difference indicators',
      warning_from_lbl: 'Warning from',
      danger_from_lbl: 'Danger from',
      save_config_btn: 'Save',
      remove_device_btn: 'Remove device',
      upgrade_device_btn: 'Upgrade device',
      yes_btn: 'Yes',
      no_btn: 'No',
      min_lbl: 'min temp.',
      avg_lbl: 'avg temp.',
      max_lbl: 'max temp.',
      diff_lbl: 'max diff.',
    },
    meteo: {
      graphs: {
        title: 'Temperature and humidity changes',
        temperature_lbl: 'Temperature',
        humidity_lbl: 'Humidity',
        temperature_short_lbl: 'Temp.',
        humidity_short_lbl: 'Hum.',
        help_lbl: 'Graph can be shifted in time with the buttons below. ' +
          'You can also select the date range for the report you\'re viewing.',
        help1_lbl: 'App might have more controls of how to display graphics on computer.',
        lbl_month: 'month',
        lbl_week: 'week',
        lbl_day: 'day',
        lbl_hour: 'hour',
        lbl_min: 'min.',
        lbl_frequency: 'Data frequency:',
      },
    },
    thermo_x4: {
      min_lbl: 'min',
      max_lbl: 'max',
      avg_lbl: 'avg',
    },
    grating: {
      lbl_manual_mode: 'Manual mode',
      lbl_stop_mode: 'STOP pressed',
      lbl_error: 'Error!',
      logs: {
        empty_lbl: 'Empty log',
      }
    },
  },
};

export default labels;
