const types = {
  DEFAULT: 'DEFAULT',
  GATES: 'GATES',
  ENGINE: 'ENGINE',
  LOCK: 'LOCK',
  SWITCH: 'SWITCH',
  T_WIRE: 'T_WIRE',
  METEO: 'METEO',
  T_WIREx4: 'T_WIREx4',
  THERMOMETER: 'THERMOMETER',
  TRAPS: 'TRAPS',
  GRATING: 'GRATING',
  NDDS75_DISTANCE: 'NDDS75_DISTANCE',
};

export { types };
