import React from 'react';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import View from './view';

const Component = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const devices = useSelector((state) => pathOr({}, ['devices', 'data'])(state));
  const list = Object.keys(devices)
    .map((key) => devices[key])
    .sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime());

  return <View id={id} list={list} t={t} />;
};

export default Component;
