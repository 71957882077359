import React from 'react';
import { NavLink } from 'react-router-dom';

import { Wifi, Water } from '../../../../../../components';

const DangerView = ({ status, t }) => {
  const icon = !status.connected ? 'fa-lg fa-exclamation-circle' : (status.stop ? 'fa-lg fa-stop-circle' : 'fa-md fa-hand-paper-o');
  const label = !status.connected ? 'devices.lbl_disconnected' : (status.stop ? 'devices.lbl_stop_mode' : 'devices.lbl_manual_mode');

  return (
    <div className="row justify-content-between align-items-center px-2">
      <h4 className="device-icon-wrapper device-circle danger text-danger d-flex align-items-center justify-content-center">
        <i className={`fa ${icon}`} />
      </h4>
      <div className="text-dark"><b>{t(label)}</b></div>
      <i className="fa fa-2x fa-exclamation-triangle text-danger" />
    </div>
  );
};

const getIconColor = (cycle) => {
  if (cycle <= 1) {
    return 'white';
  }

  return (cycle > 2) ? 'danger' : 'warning';
};

const View = ({ device, t }) => (
  <NavLink to={`/devices/${device._id}/view`} className="no-hover" draggable={false}>
    <div className="card card-stats mb-0">
      <div className="card-header bg-custom text-light py-1">
        <div className="row justify-content-between px-1">
          <div>{device.name}</div>
          <div className="text-nowrap">
            <Wifi level="4" connected={device.status.connected} />
          </div>
        </div>
      </div>
      <div className="card-body py-1">
        {(device.status.connected && device.status.auto && !device.status.stop) ? (
          <div className="row justify-content-between align-items-center px-2">
            <div className="d-flex align-items-center">
              <h4 className="device-icon-wrapper device-circle text-info d-flex align-items-center justify-content-center m-0 mr-2">
                <Water danger={device.status.maxLevel}/>
              </h4>
              <h4 className={`device-icon-wrapper text-info d-flex align-items-center justify-content-${device.status.engineOn ? 'right' : 'left'}`}>
                {device.status.engineOn ? (
                  <>
                    <i className="fa fa-lg fa-cog fa-spin fa-fw" />
                    <i className={`fa fa-sm fa-pull-left pl-1 
                  ${device.status.goingUp ? 'fa-arrow-up fa-scroll-up' : 'fa-arrow-down fa-scroll-down'}`} />
                  </>
                ) : (
                  <i className="fa fa-lg fa-cog" />
                )}
              </h4>
            </div>
            {(device.status.cycle < 3) && (
              <div>
                <span className="text-dark text-lg"><b>{device.status.pressure}</b></span>
                <span className="ml-1">bar</span>
              </div>
            )}
            {(device.status.cycle > 2) && (
              <div className="text-dark"><b>{t('devices.grating.lbl_error')}</b></div>
            )}
            <div className="d-flex align-items-center">
              <i className={`fa fa-2x fa-exclamation-triangle text-${getIconColor(device.status.cycle)}`} />
            </div>
          </div>
        ) : (
          <DangerView status={device.status} t={t} />
        )}
      </div>
    </div>
  </NavLink>
);

export default View;
