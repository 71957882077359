import config from '../../config';

export default {
  users: {
    auth: {
      login: () => ({ URL: `${config.API_HTTP_ROOT}/auth/login`, METHOD: 'POST', secure: false }),
      logout: () => ({ URL: `${config.API_HTTP_ROOT}/auth/logout`, METHOD: 'GET' }),
      refreshToken: () => ({ URL: `${config.API_HTTP_ROOT}/auth/refresh`, METHOD: 'GET' }),
    },
    profile: {
      get: () => ({ URL: `${config.API_HTTP_ROOT}/profile`, METHOD: 'GET' }),
      update: () => ({ URL: `${config.API_HTTP_ROOT}/profile`, METHOD: 'PUT' }),
      changePassword: () => ({ URL: `${config.API_HTTP_ROOT}/profile/password`, METHOD: 'PUT' }),
      changeLanguage: (code) => ({ URL: `${config.API_HTTP_ROOT}/profile/language?code=${code}`, METHOD: 'PUT' }),
    },
  },
  devices: {
    load: (id) => ({ URL: `${config.API_HTTP_ROOT}/devices/${id}`, METHOD: 'GET' }),
    loadList: () => ({ URL: `${config.API_HTTP_ROOT}/devices/list`, METHOD: 'GET' }),
    getLogs: (id, from, to) => ({ URL: `${config.API_HTTP_ROOT}/devices/${id}/logs/${from}/${to}`, METHOD: 'GET' }),
    getData: (id, query) => ({ URL: `${config.API_HTTP_ROOT}/devices/${id}/data?${query}`, METHOD: 'GET' }),
    execute: (id) => ({ URL: `${config.API_HTTP_ROOT}/devices/${id}/execute`, METHOD: 'PUT' }),
    save: (id) => ({ URL: `${config.API_HTTP_ROOT}/devices/${id}`, METHOD: 'PUT' }),
    saveConfig: (id, deviceType) => ({ URL: `${config.API_HTTP_ROOT}/devices/${id}/config/${deviceType}`, METHOD: 'PUT' }),

    // load: (id) => ({
    //   URL: `${config.CLOUD_HTTP_ROOT}/devices/${id}`,
    //   METHOD: 'GET',
    // }),
    // loadList: () => ({
    //   URL: `${config.CLOUD_HTTP_ROOT}/devices/list`,
    //   METHOD: 'GET',
    // }),
    // getFailure: (mac) => ({
    //   URL: `${config.CLOUD_HTTP_ROOT}/devices/failure/${mac}`,
    //   METHOD: 'GET',
    // }),
    // create: () => ({
    //   URL: `${config.CLOUD_HTTP_ROOT}/devices`,
    //   METHOD: 'POST',
    // }),
    // save: (id) => ({
    //   URL: `${config.CLOUD_HTTP_ROOT}/devices/${id}`,
    //   METHOD: 'PUT',
    // }),
    // getOwner: (id) => ({
    //   URL: `${config.CLOUD_HTTP_ROOT}/devices/${id}/owner`,
    //   METHOD: 'GET',
    // }),
    // add: {
    //   assign: () => ({
    //     URL: `${config.CLOUD_HTTP_ROOT}/devices/assign`,
    //     METHOD: 'POST',
    //   }),
    // },
    // groups: {
    //   load: () => ({
    //     URL: `${config.CLOUD_HTTP_ROOT}/devices/groups`,
    //     METHOD: 'GET',
    //   }),
    //   save: () => ({
    //     URL: `${config.CLOUD_HTTP_ROOT}/devices/groups`,
    //     METHOD: 'PUT',
    //   })
    // },
    // config: {
    //   save: (id) => ({
    //     URL: `${config.CLOUD_HTTP_ROOT}/devices/${id}/config`,
    //     METHOD: 'PUT',
    //   })
    // },
    // share: {
    //   add: (id) => ({
    //     URL: `${config.CLOUD_HTTP_ROOT}/devices/${id}/share`,
    //     METHOD: 'POST',
    //   }),
    //   remove: (id, email) => ({
    //     URL: `${config.CLOUD_HTTP_ROOT}/devices/${id}/share/${email}`,
    //     METHOD: 'DELETE',
    //   }),
    // },
    // keys: {
    //   add: (id) => ({
    //     URL: `${config.CLOUD_HTTP_ROOT}/devices/${id}/keys`,
    //     METHOD: 'POST',
    //   }),
    //   enable: (id, key) => ({
    //     URL: `${config.CLOUD_HTTP_ROOT}/devices/${id}/keys/${key}`,
    //     METHOD: 'PUT',
    //   }),
    //   remove: (id, key) => ({
    //     URL: `${config.CLOUD_HTTP_ROOT}/devices/${id}/keys/${key}`,
    //     METHOD: 'DELETE',
    //   }),
    // },
    // physical: {
    //   pair: () => ({
    //     URL: `${config.DEVICE_ROOT}/pair`,
    //     METHOD: 'GET',
    //   }),
    //   getNetworkList: () => ({
    //     URL: `${config.DEVICE_ROOT}/networks`,
    //     METHOD: 'GET',
    //   }),
    //   connect: () => ({
    //     URL: `${config.DEVICE_ROOT}/connect`,
    //     METHOD: 'POST',
    //   }),
    // },
    // data: {
    //   loadList: (deviceId, from, to, graph = false) => ({
    //     URL: `${config.CLOUD_HTTP_ROOT}/devices/${deviceId}/data?from=${from}&to=${to}&graph=${graph}`,
    //     METHOD: 'GET',
    //   }),
    //   loadList_v2: (deviceId, from, to, graph = false, timeFrame = 'day') => ({
    //     URL: `${config.CLOUD_HTTP_ROOT}/devices/${deviceId}/data_v2?from=${from}&to=${to}&timeFrame=${timeFrame}&graph=${graph}`,
    //     METHOD: 'GET',
    //   }),
    // },
    // reboot: (id) => ({
    //   URL: `${config.CLOUD_HTTP_ROOT}/devices/${id}/reboot`,
    //   METHOD: 'PUT',
    // }),
    // remove: (id) => ({
    //   URL: `${config.CLOUD_HTTP_ROOT}/devices/${id}`,
    //   METHOD: 'DELETE',
    // }),
    // upgrade: (id) => ({
    //   URL: `${config.CLOUD_HTTP_ROOT}/devices/${id}/upgrade`,
    //   METHOD: 'PUT',
    // }),
    // execute: (id) => ({
    //   URL: `${config.CLOUD_HTTP_ROOT}/devices/${id}/execute`,
    //   METHOD: 'PUT',
    // }),
  },
};
