import React from 'react';

import View from './view';
import schema from './schema';
import { useApiRequest } from '../../../../../../utils/hooks';
import { api as endpoints } from '../../../../../../utils/api';
import { useForm } from '../../../../../../utils/hooks/useForm';

const Component = () => {
  const { state, resetState, execute } = useApiRequest();
  const form = useForm({ password: '', password2: '' }, schema);

  const handlers = {
    onSubmit: () => execute(endpoints.users.profile.changePassword(), form.toJSON()),
    onReset: resetState,
  };

  return <View form={form} state={state} handlers={handlers} />;
};

export default Component;
