import React from 'react';
import { NavLink } from 'react-router-dom';

const Component = ({ handlers, t }) => (
  <div className="sidebar" data-color="default" data-active-color="danger">
    <div className="logo text-danger">
      <div className="mx-2 d-flex justify-content-between align-items-center">
        <span>HMI</span>
        <a href="/" className="nav-link nav-top text-white" onClick={handlers.onLogout}>
          <i className="nc-icon nc-button-power"/>
        </a>
      </div>
    </div>
    <div className="sidebar-wrapper">
      <ul className="nav">
        <li>
          <NavLink className="nav-link" to={`/devices`} data-toggle="collapse">
            <i className="nc-icon nc-settings-gear-65"/>
            <p>{t('menu.devices')}</p>
          </NavLink>
        </li>
        <li>
          <NavLink className="nav-link" to={`/profile`}>
            <i className="nc-icon nc-badge"/>
            <p>{t('menu.profile')}</p>
          </NavLink>
        </li>
      </ul>
    </div>
    <footer className="footer pb-0 d-flex align-items-center text-sm">
      <span className="copyright pr-1 text-white">© 2019-2021</span>
      <a href="https://www.eltrum.com" target="_blank" rel="noopener noreferrer" className="copyright text-white">&laquo;Eltrum Systems&raquo;</a>
    </footer>
  </div>
);

export default Component;
