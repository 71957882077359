const joi = require('joi');

const schema = joi.object({
  password: joi.string()
    .min(5)
    .max(32)
    .required(),
  password2: joi.valid(joi.ref('password'))
    .required(),
}).options({ abortEarly: false, stripUnknown: true });

export default schema;
