import React from 'react';
import { NavLink } from 'react-router-dom';

import * as Content from './pages';
import Logo from '../components/logo/horizontal';
import config from '../../config';
import Footer from './footer';

import flagLT from '../../public/assets/img/flags/lt.png';
import flagEN from '../../public/assets/img/flags/en.png';

const LANGUAGES = {
  lt: {
    shortLbl: 'LT',
    longLbl: 'Lietuviškai',
    flag: flagLT,
  },
  en: {
    shortLbl: 'EN',
    longLbl: 'English',
    flag: flagEN,
  }
};

const Component = ({ lang, languagesVisible, handlers, t }) => (
  <div className="wrapper wrapper-full-page theme-bg">
    <div className="full-page">
      <header className="header-area header_fix">
        <div className="container">
          <div className="d-flex">
            <div className="w-100">
              <div className="logo new">
                <a href={config.WEB_ROOT}><Logo color="#fff" /></a>
              </div>
            </div>
            <div className="flex-shrink-1 align-self-center text-nowrap pr-3 mt-1 text-white text-sm"
                 onClick={handlers.onShowLangList}>
              <img src={LANGUAGES[lang].flag} height={20} alt="language"/>
              <span className="sidebar-normal ml-2 mr-2"><b>{LANGUAGES[lang].shortLbl}</b></span>
              <i className="fa fa-sm fa-caret-down"/>
            </div>
            <ul className="navbar-nav mt-5">
              <li className={`nav-item btn-rotate dropdown ${languagesVisible ? 'show' : ''}`}>
                <div className="dropdown-menu dropdown-menu-right">
                  {Object.entries(LANGUAGES).map(([key, language]) => (
                    <NavLink to={`?lang=${key}`} key={key} className="dropdown-item" onClick={handlers.onChangeLanguage(key)}>
                      <img src={language.flag} height={20} alt="language"/>
                      <span>{language.longLbl}</span>
                    </NavLink>
                  ))}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <div className="content">
        <div className="container">
          <div className="col-lg-4 col-md-6 ml-auto mr-auto">
            <Content.Login />
          </div>
        </div>
      </div>
    </div>
    <Footer t={t} />
  </div>
);

export default Component;
