import React from 'react';

import { Form } from '../../../components';

const errors = {
  401: 'auth.login.error_401',
  403: 'auth.login.error_403',
};

const Component = ({ form, state, handlers, t }) => (
  <div className="form">
    <div className="card card-login">
      <div className="card-header text-lg text-center mb-2">{t('auth.login.title')}</div>
      <Form.Progress on={state.pending} />
      <hr className="mt-0"/>
      <div className="card-body pt-1">
        <Form.Error result={state} errors={errors} onClear={handlers.onReset} t={t} />
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="nc-icon nc-single-02"/>
            </span>
          </div>
          <input {...form.field('username')} className="form-control" placeholder={t('auth.login.lbl_username')} />
        </div>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="nc-icon nc-key-25"/>
            </span>
          </div>
          <input type="password" {...form.field('password')} className="form-control" placeholder={t('auth.login.lbl_password')} />
        </div>
      </div>
      <div className="card-footer pt-0 pl-3 pr-3 pb-1">
        <div>
          <Form.Submit form={form} label={t('auth.login.btn_submit')} className="btn btn-info btn-block mb-3" onSubmit={handlers.onSubmit} />
        </div>
      </div>
    </div>
  </div>
);

export default Component;
