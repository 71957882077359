import React from 'react';
import { Route } from 'react-router-dom';

import Logs from './logs';
import Settings from './settings';

const View = ({ id }) => (
  <div className="row p-0 m-0">
    <div className="col-8 p-0 m-0">
      <Logs id={id} />
    </div>
    <div className="col-4">
      <Route path="/devices/:id/settings"><Settings id={id} /></Route>
    </div>
  </div>
);

export default View;
