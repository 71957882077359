import React from 'react';
import { useParams } from 'react-router-dom';

import NormalView from './view';
import MiniView from './mobile';

const Component = () => {
  const { id } = useParams();

  return (window.innerWidth >= 1024) ? <NormalView id={id} /> : <MiniView id={id} />;
};

export default Component;
