const joi = require('joi');

const schema = joi.object({
  measurementType: joi.number()
    .min(1)
    .max(3)
    .required(),
  minHeight: joi.number()
    .min(0)
    .required(),
  containerHeight: joi.number()
    .min(0)
    .required(),
  minDistanceTop: joi.number()
    .min(0)
    .allow(null).allow(''),
  maxDistanceTop: joi.number()
    .min(0)
    .allow(null).allow(''),
  minDistanceBottom: joi.number()
    .min(0)
    .allow(null).allow(''),
  maxDistanceBottom: joi.number()
    .min(0)
    .allow(null).allow(''),
  minPercent: joi.number()
    .min(0)
    .max(100)
    .allow(null).allow(''),
  maxPercent: joi.number()
    .min(0)
    .max(100)
    .allow(null).allow(''),
  sendEmail: joi.bool(),
}).options({ abortEarly: false, stripUnknown: true });

export default schema;
