import React from 'react';
import { useTranslation } from 'react-i18next';

import View from './view';

const Component = ({ props }) => {
  const { t } = useTranslation();

  const device = {
    ...props,
    connected: props?.connected || false,
    data: {
      ...props.data,
      acum: props?.data?.acum || false,
      wifi: props?.data?.wifi || false,
    }
  };

  return <View device={device} t={t} />;
};

export default Component;
