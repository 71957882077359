import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';

import View from './view';
import config from '../../config';
import { app } from '../../lib';

const Component = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const lang = useSelector((state) => pathOr(config.LANG, ['language', 'code'])(state));

  const [languagesVisible, setLanguagesVisible] = useState(false);
  const handlers = {
    onShowLangList: () => setLanguagesVisible(!languagesVisible),
    onChangeLanguage: (key) => () => {
      new Promise(() => i18n.changeLanguage(`${key}`)).then(() => {});
      dispatch(app.setLanguage.action(key));
      setLanguagesVisible(false);
    },
  };

  return <View lang={lang} languagesVisible={languagesVisible} handlers={handlers} t={t} />;
};

export default Component;
