import React, { useEffect, useState } from 'react';
import { isEmpty } from 'ramda';

import View from './view';
import schema from './schema';
import { useApiRequest } from '../../../../../../utils/hooks';
import { api as endpoints } from '../../../../../../utils/api';
import { useForm } from '../../../../../../utils/hooks/useForm';

const Component = ({ data }) => {
  const { state, resetState, execute } = useApiRequest();
  const [loaded, setLoaded] = useState(false);

  const form = useForm({ username: '', email: '' }, schema);
  useEffect(() => {
    if (!isEmpty(data) && !loaded) {
      setLoaded(true);
      form.populate({ username: data.username, email: data.email });
    }
  }, [form, loaded, data]);

  const handlers = {
    onSubmit: () => execute(endpoints.users.profile.update(), form.toJSON()),
    onReset: resetState,
  };

  return <View form={form} state={state} handlers={handlers} />;
};

export default Component;
