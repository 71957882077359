import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { Form } from '../../../../../../../components';
import { MEASUREMENT_TYPE } from '../../../../../../../../lib/devices/distance';

const View = ({ device, apiState, data, measurementType, date, handlers, t }) => (
  <div className="card">
    <div className="card-header border-bottom">
      <div className="row align-items-center mb-2">
        <h5 className="col-md-4 card-title d-flex justify-content-md-start justify-content-center">Grafikas</h5>
        <h5 className="col-md-4 card-title d-flex justify-content-center">{date}</h5>
        <div className="col-md-4 d-flex justify-content-md-end justify-content-center align-items-center">
          <button disabled={apiState.pending} onClick={handlers.onTimelineMove(-1)} className="btn btn-primary btn-sm btn-ctrl m-1">
            <i className="fa fa-icon fa-angle-left"/>
          </button>
          <button disabled={apiState.pending} onClick={handlers.onTimelineMove(0)} className="btn btn-primary btn-sm btn-ctrl m-1">
            <i className="fa fa-icon fa-circle"/>
          </button>
          <button disabled={apiState.pending} onClick={handlers.onTimelineMove(1)} className="btn btn-primary btn-sm btn-ctrl m-1">
            <i className="fa fa-icon fa-angle-right"/>
          </button>
        </div>
      </div>
    </div>
    <Form.Progress on={apiState.pending} />
    {data && (
      <div className="container mt-2">
        <div className="row">
          <div style={{width: '100%', height: 500}}>
            <ResponsiveLine
              data={data.values}
              theme={{ text: { fontSize: 10, fontFamily: "Montserrat" } }}
              margin={{ top: 5, right: 20, bottom: 60, left: 40 }}
              xScale={{ type: 'point' }}
              yScale={{ type: 'linear', min: data.limits.min, max: data.limits.max, stacked: false, reverse: false }}
              curve="monotoneX"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                fontSize: '5px',
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -45,
                legend: '',
                legendOffset: 100,
                legendPosition: 'middle'
              }}
              axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendOffset: -40,
                legendPosition: 'middle'
              }}
              colors={{ scheme: 'set2' }}
              pointSize={8}
              pointBorderWidth={2}
              pointColor={{ from: 'color', modifiers: [] }}
              pointBorderColor="#ffffff"
              pointLabel="y"
              pointLabelYOffset={-12}
              useMesh={true}
              animate={false}
              motionStiffness={100}
              motionDamping={13}
              tooltip={({ point }) => (
                <div className="graph-tooltip">
                  <div className="container text-sm">
                    <span className="color" style={{ background: point.serieColor }}/>
                    <span>{point.serieId}:&nbsp;</span>
                    <span>
                      <b>{point.data.y.toFixed(2)} </b>
                      {measurementType === MEASUREMENT_TYPE.PERCENT ? '%' : 'cm'}
                    </span>
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </div>
    )}
  </div>
);

export default View;
