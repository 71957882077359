import { actions, api } from '../../utils/api';

const NAMESPACE = 'USER_LOGOUT_NAMESPACE';
const TYPE = 'USER_LOGOUT';

const action = () => (dispatch) => {
  dispatch(actions.callAPI(NAMESPACE, api.users.auth.logout()));
  dispatch({ type: TYPE });
};

export { action, TYPE };
