import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';

import { user, devices } from '../../lib';
import * as messenger from '../../utils/messenger';
import NormalView from './view';
import MiniView from './mobile';

const setDeviceStatus = (dispatch) => (message) => {
  const { c: command, ...data } = JSON.parse(message);
  if (command === 'device') {
    dispatch(devices.save.action(data));
  }
};

const Component = () => {
  const dispatch = useDispatch();

  const userData = useSelector((state) => pathOr({}, ['user'])(state));
  useEffect(() => {
    const callback = { key: 'device', fn: setDeviceStatus(dispatch) };
    messenger.subscribe(`user/${userData.id}`, callback);
    return () => messenger.unsubscribe(`user/${userData.id}`, callback);
  }, [dispatch, userData.id]);

  useEffect(() => {
    dispatch(user.getData.action());
    dispatch(devices.loadList.action());
  }, [dispatch]);

  return (window.innerWidth >= 1024) ? <NormalView /> : <MiniView />;
};

export default Component;
