import React from 'react';

const FormFieldInput = ({ form, field, label, className, ...rest }) => (
  <>
    <label>{label}</label>
    <div className="form-group">
      <input
        {...form.field(field)}
        className={`form-control ${className} ${form.state.fields[field].error ? 'border-danger' : ''}`}
        {...rest}
      />
    </div>
  </>
);

export {
  FormFieldInput,
}
