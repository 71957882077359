import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { pathOr } from 'ramda';
import moment from 'moment';
import View from './view';
import { useApiRequest } from '../../../../../../../../utils/hooks';
import { api as endpoints } from '../../../../../../../../utils/api';
import {
  appendDefaultConfig,
  MEASUREMENT_TYPE,
  VALUE_BY_MEASUREMENT_TYPE_FN
} from '../../../../../../../../lib/devices/distance';

const AXIS_LBL_BY_MEASUREMENT_TYPE = {
  [MEASUREMENT_TYPE.FROM_TOP]: 'Atstumas',
  [MEASUREMENT_TYPE.FROM_BOTTOM]: 'Gylis',
  [MEASUREMENT_TYPE.PERCENT]: 'Užpildymas'
};

const Component = ({ id }) => {
  const { t } = useTranslation();
  const device = useSelector((state) => pathOr({}, ['devices', 'data', id])(state));

  const [daysBefore, setDaysBefore] = useState(0);
  const { state: apiState, execute: apiExecute } = useApiRequest();
  useEffect(() => {
    const dateFrom = moment().add(daysBefore, 'day').startOf('day').toISOString();
    const dateTo = moment().add(daysBefore, 'day').endOf('day').toISOString();
    const query = `from=${dateFrom}&to=${dateTo}&timeFrame=DAY&graph=true`;
    apiExecute(endpoints.devices.getData(id, query)).then();
  }, [apiExecute, id, daysBefore]);

  const { measurementType, containerHeight, minHeight } = appendDefaultConfig(device.config);
  const [graphData, setGraphData] = useState(null);
  useEffect(() => {
    if (apiState.success && apiState.data) {
      const data = apiState.data.map((entry) => ({
        x: moment(entry.time).format('HH:mm'),
        y: VALUE_BY_MEASUREMENT_TYPE_FN[measurementType](entry.value, containerHeight, minHeight),
      }));
      const fixedData = [ ...Array(24).keys() ].map((hourNo) => {
        const timeFrame = moment().startOf('day').add(hourNo, 'hour').format('HH:mm');
        const existing = data.find((entry) => entry.x === timeFrame);
        return { x: timeFrame, y: existing?.y || null };
      });
      const values = data.map((entry) => entry.y);
      const min = values.length ? Math.min(...values) : 0;
      const max = values.length ? Math.max(...values) : 0;
      const delta = (max - min) / 10;
      const limitMin = min - delta;
      const limitMax = max + delta;
      setGraphData({
        values: [{ id: AXIS_LBL_BY_MEASUREMENT_TYPE[measurementType], data: fixedData }],
        limits: { min: limitMin, max: limitMax },
      });
    }
  }, [apiState, containerHeight, minHeight, measurementType]);

  const handlers = {
    onTimelineMove: (delta) => () => setDaysBefore(delta ? daysBefore + delta : delta),
  };

  return <View
    device={device}
    apiState={apiState}
    data={graphData}
    measurementType={measurementType}
    date={moment().add(daysBefore, 'day').format('YYYY-MM-DD')}
    handlers={handlers}
    t={t}
  />;
};

export default Component;
