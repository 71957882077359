import React from 'react';
import { NavLink } from 'react-router-dom';

import { Wifi } from '../../../../../../components';
import { MEASUREMENT_TYPE } from '../../../../../../../lib/devices/distance';

const DangerView = () => {
  const icon = 'fa-lg fa-exclamation-circle';
  // const label = 'devices.lbl_disconnected';

  return (
    <div className="row justify-content-between align-items-center px-3">
      <h4 className="device-icon-wrapper device-circle danger text-danger d-flex align-items-center justify-content-center">
        <i className={`fa ${icon}`} />
      </h4>
      <div className="text-dark"><b>Prietaiso klaida</b></div>
      <i className="fa fa-2x fa-exclamation-triangle text-danger" />
    </div>
  );
};

const View = ({ device, data, t }) => (
  <NavLink to={`/devices/${device._id}/view`} className="no-hover" draggable={false}>
    <div className="card card-stats mb-0">
      <div className="card-header bg-custom text-light py-1">
        <div className="row justify-content-between px-1">
          <div>{device.name}</div>
          <div className="text-nowrap">
            <Wifi level="4" connected={data.connected} />
          </div>
        </div>
      </div>
      <div className="card-body py-1 px-2">
        {(data.connected && (data.value !== null)) ? (
            <div className="d-flex justify-content-between align-items-center text-info text-md">
              <h4 className="device-icon-wrapper device-circle text-info d-flex align-items-center justify-content-center m-0 mr-2">
                <i className="text-xlg fa fa-md fa-water-arrow-down" />
              </h4>
              <div className="w-75 text-xlg d-flex justify-content-around align-items-center">
                <div>
                  <span><b>{data.raw.toFixed(0)} cm</b></span>
                </div>
                {(!!data.containerHeight && (data.measurementType !== MEASUREMENT_TYPE.FROM_TOP)) ? (
                  <>
                    <span className="ml-1 text-xlg">|</span>
                    <div className={data.danger ? 'text-danger' : data.warning ? 'text-warning' : ''}>
                      <span><b>{data.value.toFixed(0)}</b></span>
                      <span><b>{(data.measurementType === MEASUREMENT_TYPE.PERCENT) ? '%' : ' cm'}</b></span>
                    </div>
                  </>
                ) : ''}
              </div>
            </div>
        ) : (
          <DangerView t={t} />
        )}
      </div>
    </div>
  </NavLink>
);

export default View;
