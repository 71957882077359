import { useMemo, useState } from 'react';

const createInitialState = (fields, schema, isReady) => {
  const { error: validationError } = schema.validate(fields);

  return {
    hasErrors: !!validationError,
    isDirty: false,
    isReady,
    isDisabled: !isReady,
    fields: Object.keys(fields).reduce(
      (obj, fieldName) => ({
        ...obj,
        [fieldName]: {
          name: fieldName,
          value: fields[fieldName],
          isDirty: false,
          error: getFieldValidationError(fieldName, validationError?.details),
        },
      }),
      {}
    ),
  };
}

const getFieldValidationError = (fieldName, errors) => {
  if (errors) {
    const err = errors.find((err) => err.path.includes(fieldName));
    return err ? err.message : null;
  }
  return null;
}

const useForm = (defaults, schema, isReady = true) => {
  const initialState = useMemo(() =>
    createInitialState(defaults, schema, isReady),
    [defaults, schema, isReady]
  );
  const [state, setState] = useState(initialState);

  const onChange = (e) => {
    const value = (e.target.type === 'checkbox') ? e.target.checked : e.target.value;
    const newFormValues = { ...toJSON(), [e.target.name]: value };
    const { error: validationError } = schema.validate(newFormValues);

    setState({
      ...state,
      hasErrors: !!validationError,
      isDirty: true,
      fields: {
        ...state.fields,
        [e.target.name]: {
          name: e.target.name,
          value: value,
          isDirty: true,
          error: getFieldValidationError(e.target.name, validationError?.details),
        },
      },
    });
  };

  const toJSON = () => (
    Object.keys(state.fields).reduce(
      (obj, fieldName) => ({
        ...obj,
        [fieldName]: state.fields[fieldName].value,
      }),
      {}
    )
  );

  const populate = (data) => setState(createInitialState(data, schema, true));

  const setIsReady = (isReady) => setState({ ...state, isReady });

  const setIsDisabled = (isDisabled) => setState({ ...state, isDisabled });

  const reset = () => setState(createInitialState(defaults, schema, true));

  const field = (fieldName) => ({
    name: state.fields[fieldName].name,
    value: state.fields[fieldName].value,
    checked: state.fields[fieldName].value,
    disabled: state.isDisabled,
    onChange,
  });

  return {
    state,
    onChange,
    toJSON,
    populate,
    setIsReady,
    setIsDisabled,
    reset,
    field,
  };
};

export { useForm };
