import React from 'react';
import { NavLink } from 'react-router-dom';

const View = ({ id }) => (
  <div className="card-header bg-light border-bottom border-right border-left rounded-bottom p-2 shadow-sm">
    <div className="device-menu px-2">
      <NavLink to={`/devices/${id}/view`}>
        <i className="nc-icon nc-alert-circle-i"/>
      </NavLink>
      <NavLink to={`/devices/${id}/graph`}>
        <i className="nc-icon nc-chart-bar-32" />
      </NavLink>
      <NavLink to={`/devices/${id}/config`}>
        <i className="nc-icon nc-settings-gear-65" />
      </NavLink>
      <NavLink to={`/devices/${id}/settings`}>
        <i className="nc-icon nc-settings" />
      </NavLink>
    </div>
  </div>
);

export default View;
