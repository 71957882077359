import React from 'react';
import { NavLink } from 'react-router-dom';

import { Battery, Wifi } from '../../../../../../components';

const buttonStatus = (status) => {
  if (status.error) {
    return 'btn-danger';
  } else if (status.success) {
    return 'btn-success';
  } else {
    return (status.pending ? 'btn-warning' : 'btn-info');
  }
};

const icon = (status) => {
  if (status.error) {
    return 'nc-icon nc-simple-remove';
  } else if (status.success) {
    return 'nc-icon nc-check-2';
  } else {
    return (status.pending ? 'fa fa-spinner fa-pulse fa-1x fa-fw' : 'fa fa-2x fa-power-off');
  }
};

const statusColor = (device, status) => (status.pending ? 'warning' : (device.status.on ? 'success' : 'danger'));

const DangerView = ({ status, t }) => {
  const icon = !status.connected ? 'fa-lg fa-exclamation-circle' : (status.stop ? 'fa-lg fa-stop-circle' : 'fa-md fa-hand-paper-o');
  const label = !status.connected ? 'devices.lbl_disconnected' : (status.stop ? 'devices.lbl_stop_mode' : 'devices.lbl_manual_mode');

  return (
    <div className="row justify-content-between align-items-center px-2">
      <h4 className="device-icon-wrapper device-circle danger text-danger d-flex align-items-center justify-content-center">
        <i className={`fa ${icon}`} />
      </h4>
      <div className="text-dark"><b>{t(label)}</b></div>
      <i className="fa fa-2x fa-exclamation-triangle text-danger" />
    </div>
  );
};

const View = ({ device, status, handlers, t }) => (
  <NavLink to={`/devices/${device._id}/view`} className="no-hover">
    <div className="card card-stats mb-0">
      <div className="card-header bg-custom text-light py-1">
        <div className="row justify-content-between px-1">
          <div>{device.name}</div>
          <div className="text-nowrap">
            {device.status.acum && <Battery level={device.status.acum} />}
            {(device.status.wifi >= 0) && <Wifi level={device.status.wifi} connected={device.status.connected} />}
          </div>
        </div>
      </div>
      <div className="card-body py-1">
        {(device.status.connected && device.status.auto && !device.status.stop) ? (
          <div className="row justify-content-between align-items-center px-2">
            <h4 className="device-icon-wrapper text-info d-flex align-items-center justify-content-center m-0">
              <i className={`fa fa-lg fa-cog ${device.status.on ? 'fa-spin fa-fw' : ''}`} />
            </h4>
            <button type="button" onClick={handlers.onActivate}
                    className={`btn btn-device ${buttonStatus(status)} ${!device.connected ? 'd-disabled' : ''}`}>
              <i className={icon(status)} />
            </button>
            <div className="text-right pt-2 pr-1">
              <i className={`fa fa-circle text-${statusColor(device, status)}`} />
            </div>
          </div>
        ) : (
          <DangerView status={device.status} t={t} />
        )}
      </div>
    </div>
  </NavLink>
);

export default View;
