import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Devices from './devices';
import Profile from './profile';

const Component = () => (
  <Switch>
    <Route path="/devices/:id"><Devices /></Route>
    <Route path="/devices"><Devices /></Route>
    <Route path="/profile"><Profile /></Route>
    <Redirect to="/devices" />
  </Switch>
);

export default Component;
