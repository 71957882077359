import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { pathOr } from 'ramda';

import View from './view';

const Component = ({ id }) => {
  const { t } = useTranslation();

  const device = useSelector((state) => pathOr({}, ['devices', 'data', id])(state));

  return <View device={device} t={t} />;
};

export default Component;
