import React from 'react';

const Component = ({ danger = false }) => {
  return danger ? (
    <svg id="water" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 338.839 338.839" width="18px" height="18px">
      <g>
        <path style={{ fill: '#51bcda' }} d="M320.392,18.183c-10.189,0-18.446,8.259-18.446,18.446V62.78l-34.756,20.882c-2.774,1.675-9.344,1.618-12.105-0.129
        l-25.646-16.12c-14.327-9.01-35.53-9.716-50.416-1.693l-33.931,18.27c-2.963,1.597-9.764,1.267-12.568-0.604l-22.83-15.255
        c-14.261-9.529-35.542-10.496-50.612-2.293L36.894,77.921V36.629c0-10.187-8.256-18.446-18.447-18.446
        C8.26,18.183,0,26.442,0,36.629v247.134c0,20.35,16.549,36.893,36.894,36.893h265.052c20.345,0,36.894-16.543,36.894-36.893
        V36.629C338.839,26.442,330.576,18.183,320.392,18.183z M301.945,283.775H36.888V119.937l39.833-21.695
        c2.933-1.591,9.686-1.297,12.466,0.567l22.83,15.255c14.234,9.511,35.503,10.526,50.575,2.408l33.921-18.267
        c3.111-1.666,10.305-1.438,13.295,0.447l25.652,16.123c14.604,9.182,35.957,9.403,50.74,0.511l15.751-9.464v177.952H301.945z"/>
        <path style={{ fill: '#ef8157' }} d="M178.69,148.484c-5.125-5.122-13.417-5.122-18.54,0l-40.172,40.175c-5.122,5.116-3.399,9.271,3.837,9.271h19.38v39.325
        c0,7.236,5.873,13.109,13.112,13.109h26.213c7.242,0,13.115-5.873,13.115-13.109V197.93h19.383c7.23,0,8.96-4.155,3.831-9.271
        L178.69,148.484z"/>
      </g>
    </svg>
  ) : (
    <svg id="water" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 55.105 55.105" width="18px" height="18px">
      <g>
        <path style={{ fill: '#51bcda' }}  d="M52.106,2.957c-1.657,0-3,1.343-3,3V29.21l-5.653,3.397c-0.451,0.271-1.52,0.263-1.969-0.021l-4.172-2.621
        c-2.329-1.466-5.778-1.58-8.198-0.275l-5.518,2.971c-0.481,0.26-1.589,0.206-2.044-0.098l-3.713-2.481
        c-2.319-1.55-5.78-1.707-8.231-0.373L6,31.674V5.957c0-1.657-1.343-3-3-3c-1.657,0-3,1.343-3,3v40.191c0,3.31,2.691,6,6,6h43.105
        c3.309,0,6-2.69,6-6V5.957C55.106,4.3,53.764,2.957,52.106,2.957z M49.106,46.15H6v-7.645l6.478-3.529
        c0.477-0.26,1.575-0.211,2.027,0.093l3.713,2.481c2.315,1.547,5.774,1.711,8.225,0.391l5.518-2.971
        c0.504-0.271,1.674-0.232,2.161,0.073l4.171,2.622c2.377,1.493,5.85,1.529,8.252,0.083l2.562-1.539L49.106,46.15L49.106,46.15z"/>
        <path style={{ fill: '#6bd098' }} d="M29.686,6.723h-4.264c-1.177,0-2.132,0.954-2.132,2.132v6.396h-3.152c-1.177,0-1.457,0.675-0.624,1.508l6.533,6.533
        c0.833,0.833,2.182,0.833,3.014,0l6.533-6.533c0.834-0.833,0.554-1.508-0.623-1.508h-3.152V8.855
        C31.817,7.677,30.862,6.723,29.686,6.723z"/>
      </g>
    </svg>
  );
};

export default Component;
