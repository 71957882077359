import React from 'react';

import Profile from './profile';
import Password from './password';

const Component = ({ data }) => (
  <div className="row m-3">
    <div className="col-5">
      <Profile data={data} />
    </div>
    <div className="col-5">
      <Password data={data} />
    </div>
  </div>
);

export default Component;
