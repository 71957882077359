import React from 'react';

// import Menu from './menu';
import Content from './pages';

const Component = () => (
  <div className="wrapper">
    <Content />
  </div>
);

export default Component;
