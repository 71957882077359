import './console';
import DEFAULT_LANGUAGE from './i18n';

const config = {
  API_HTTP_ROOT:
    `${process.env.REACT_APP_API_HTTP_PROTOCOL}://` +
    `${process.env.REACT_APP_API_HTTP_HOST}:` +
    `${process.env.REACT_APP_API_HTTP_PORT}`,
  MQTT_ROOT:
    `${process.env.REACT_APP_MQTT_PROTOCOL}://` +
    `${process.env.REACT_APP_MQTT_HOST}:` +
    `${process.env.REACT_APP_MQTT_PORT}`,

  SENTRY_DSN: `${process.env.REACT_APP_SENTRY_DSN}`,

  LANG: DEFAULT_LANGUAGE,
};

export default config;
