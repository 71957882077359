import React from 'react';

import { Form } from '../../../../../components';

const Component = ({ form, state, handlers }) => (
  <div className="card">
    <div className="card-header border-bottom">
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="card-title">Change password</h5>
      </div>
    </div>
    <Form.Progress on={state.pending} />
    <Form.Messages form={form} state={state} onClose={handlers.onReset} />
    <div className="card-body">
      <form onSubmit={handlers.onSubmit} onChange={handlers.onReset}>
        <label>New password</label>
        <div className="form-group">
          <input {...form.field('password')} type="password" maxLength={32} className="form-control" placeholder="New password" />
        </div>
        <label>Repeat password</label>
        <div className="form-group">
          <input {...form.field('password2')} type="password" maxLength={100} className="form-control" placeholder="Repeat password"/>
        </div>
      </form>
    </div>
    <div className="card-footer border-top">
      <Form.Submit form={form} state={state} label="Save" onSubmit={handlers.onSubmit} />
    </div>
  </div>
);

export default Component;
