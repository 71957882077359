import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import View from './view';
import { getDataByConfig } from '../../../../../../../lib/devices/distance';

const CONNECTION_THRESHOLD = 20 * 3 * 60;

const Component = ({ props }) => {
  const { t } = useTranslation();

  const timeDiff = moment().diff(moment(props.updated), 'seconds');
  const deviceData = {
    connected: props.status?.connected && (timeDiff < CONNECTION_THRESHOLD),
    ...getDataByConfig(props.status?.distance?.[0]?.value, props.config),
  };

  return <View device={props} data={deviceData} t={t} />;
};

export default Component;
