import React from 'react';
import { isEmpty } from 'ramda';

const systemErrors = {
  500: 'system.error_500',
};

const Progress = ({ on = false, success = false, error = false }) => {
  const invisible = !on && !success && !error;
  // const classes = `${invisible ? 'progressBarInvisible' : ''} ${error ? 'bg-danger' : ''} ${success ? 'bg-success' : ''}`;
  const classes = `${invisible ? 'border-bottom' : ''} ${error ? 'bg-danger' : ''} ${success ? 'bg-success' : ''}`;
  return (
    <div className={`progressBarRoot ${classes}`}>
      {on && (
        <>
          <div className="progressBar bar1Indeterminate" />
          <div className="progressBar bar2Indeterminate" />
        </>
      )}
    </div>
  );
};

const Error = ({ result, errors, onClear, t }) => {
  errors = { ...systemErrors, ...errors };
  if (result?.error) {
    console.log('--- ERROR:', result.error?.data?.message);
  }
  return result?.error ? (
    <div className="alert alert-danger alert-dismissible fade show" data-notify="container">
      <button type="button" aria-hidden="true" className="close" data-dismiss="alert" onClick={onClear}>
        <i className="nc-icon nc-simple-remove"/>
      </button>
      <span data-notify="message">
      {errors[result.error.status] ? t(errors[result.error.status]) : t(errors[500])}
    </span>
    </div>
  ) : null;
};

const Messages = ({ form, state, successMessage = 'Success.', showSuccess = true, onClose }) => (
  <>
    {form && form.errors && !isEmpty(form.errors) && (
      <div className="card-body bg-warning text-white py-2 border-bottom">
        {Object.keys(form.errors).map((key, idx) => (
          <div key={idx}>{form.errors[key].message}</div>
        ))}
      </div>
    )}
    {state.error && (
      <div className="card-body bg-danger text-white py-2 border-bottom d-flex justify-content-between">
        <div>
          <div>{state.error.message}</div>
          {state.error.data && state.error.data.message && (
            <div>{state.error.data.message}</div>
          )}
        </div>
        <button type="button" className="close flex-shrink-1 ml-2" onClick={onClose}>
          <i className="nc-icon nc-simple-remove"/>
        </button>
      </div>
    )}
    {showSuccess && state.success && (
      <div className="card-body bg-success text-white py-2 border-bottom d-flex justify-content-between">
        <div className="w-100">{successMessage}</div>
        <button type="button" className="close flex-shrink-1 ml-2" onClick={onClose}>
          <i className="nc-icon nc-simple-remove"/>
        </button>
      </div>
    )}
  </>
);

const Submit = ({ form, label = 'Submit', onSubmit, ...rest }) => (
  <button type="submit" className="btn btn-info float-right" onClick={onSubmit}
          disabled={form.state.hasErrors || form.state.isDisabled} {...rest}
  >
    {label}
  </button>
);

export { Progress, Error, Messages, Submit };
