import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty, pathOr } from 'ramda';
import moment from 'moment';

import View from './view';
import { useApiRequest } from '../../../../../../../../utils/hooks';
import * as messenger from '../../../../../../../../utils/messenger';
import { api as endpoints } from '../../../../../../../../utils/api';
import { getDataByConfig, MEASUREMENT_TYPE } from '../../../../../../../../lib/devices/distance';

const SEVERITY = {
  info: 0,
  success: 1,
  warn: 2,
  danger: 3,
  unknown: 4,
};

const getLabel = (data, distanceData) => {
  const units = (data.measurementType === MEASUREMENT_TYPE.PERCENT) ? '%' : ' cm';
  const defaultValue = (data.measurementType === MEASUREMENT_TYPE.FROM_TOP)
    ? ''
    : ` (${distanceData.value.toFixed(0)} cm)`;
  return {
    text: `${data.value.toFixed(0)}${units}${defaultValue}`,
    severity: data.danger ? SEVERITY.danger : data.warning ? SEVERITY.warn : SEVERITY.success,
  };
}

const updateDeviceLog = (id, list, setList, selectedDate, setSelectedDate) => (message) => {
  const { c: command, ...data } = JSON.parse(message);
  if ((command === 'device_log') && (`${data.deviceId}` === `${id}`)) {
    // new day started?
    if (moment().startOf('day').isAfter(moment(selectedDate, 'YYYY.MM.DD'))) {
      setSelectedDate(moment().format('YYYY.MM.DD'));
      setList([data]);
    } else {
      setList([...list, data]);
    }
  }
};

const Component = ({ id }) => {
  const { t } = useTranslation();
  const device = useSelector((state) => pathOr({}, ['devices', 'data', id])(state));

  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY.MM.DD'));
  useEffect(() => {
    setSelectedDate(moment().format('YYYY.MM.DD'));
  }, [id]);

  const currentDate = moment();
  let dates = [currentDate.format('YYYY.MM.DD')];
  for (let i = 0; i < 6; i++) {
    dates.push(currentDate.subtract(1, 'days').format('YYYY.MM.DD'));
  }

  const { state, execute, resetState } = useApiRequest();
  useEffect(() => {
    if (selectedDate !== null) {
      const dateFrom = moment(selectedDate, 'YYYY.MM.DD').startOf('day').toISOString();
      const dateTo = moment(selectedDate, 'YYYY.MM.DD').endOf('day').toISOString();
      execute(endpoints.devices.getLogs(id, dateFrom, dateTo)).then();
    }
  }, [execute, id, selectedDate]);

  const [list, setList] = useState([]);
  useEffect(() => {
    const result = pathOr([], ['data'])(state);
    setList(isEmpty(result) ? [] : result);
  }, [state]);

  const userData = useSelector((state) => pathOr({}, ['user'])(state));
  useEffect(() => {
    if (moment().format('YYYY.MM.DD') === selectedDate) {
      const callback = { key: 'log', fn: updateDeviceLog(id, list, setList, selectedDate, setSelectedDate) };
      messenger.subscribe(`user/${userData.id}`, callback);
      return () => messenger.unsubscribe(`user/${userData.id}`, callback);
    }
  }, [userData.id, id, selectedDate, list]);

  const logs = list
    .filter(item => !isNaN(item?.data?.distance?.[0]?.value))
    .map(item => {
      const rawValue = item?.data?.distance?.[0]?.value;
      const distanceData = getDataByConfig(rawValue, { measurementType: MEASUREMENT_TYPE.FROM_TOP });
      const data = getDataByConfig(rawValue, device.config);
      return ({ ...item, data: data.value, label: getLabel(data, distanceData) });
    })
    .reverse();

  const handlers = {
    onDateSelect: (date) => () => {
      setSelectedDate(date === selectedDate ? null : date);
    },
    onResetState: resetState,
  };

  return <View
    state={state}
    logs={logs}
    dates={dates}
    selectedDate={selectedDate}
    handlers={handlers}
    t={t}
  />;
};

export default Component;
