import React from 'react';
import { Route } from 'react-router-dom';

import Logs from './logs';
import Graph from './graph';
import Config from './config';
import Settings from './settings';

const View = ({ id }) => (
  <div className="row p-0 m-0">
    <Route path="/devices/:id/graph">
      <div className="col-12 p-0 m-0"><Graph id={id} /></div>
    </Route>
    <Route path="/devices/:id/config">
      <div className="col-12 p-0 m-0"><Config id={id} /></div>
    </Route>
    <Route path="/devices/:id/settings">
      <div className="col-6"><Settings id={id} /></div>
    </Route>
    <Route exact path="/devices/:id/view">
      <div className="col-6 p-0 m-0"><Logs id={id} /></div>
    </Route>
  </div>
);

export default View;
