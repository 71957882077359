const joi = require('joi');

const schema = joi.object({
  username: joi.string(),
  email: joi.string().lowercase()
    .min(5)
    .max(50)
    .required()
    .email({ tlds: {allow: false} }),
}).options({ abortEarly: false, stripUnknown: true });

export default schema;
