import React from 'react';
import { Route } from 'react-router-dom';

import Logs from './logs';
import Settings from './settings';
import Graph from './graph';
import Config from './config';

const View = ({ id }) => (
  <div className="col-12 p-2 bg-light">
    <Route path="/devices/:id/graph"><Graph id={id} /></Route>
    <Route path="/devices/:id/config"><Config id={id} /></Route>
    <Route path="/devices/:id/settings"><Settings id={id} /></Route>
    <Route exact path="/devices/:id/view"><Logs id={id} /></Route>
  </div>
);

export default View;
