import { actions, createActionTypes, api } from '../../utils/api';

const NAMESPACE = 'LOAD_LIST_DEVICES';
const TYPES = createActionTypes(NAMESPACE);

const handlers = ({
  onSuccess: (data) => (dispatch) => {
    dispatch({ type: TYPES.SUCCESS, payload: data.reduce((obj, entity) => ({ ...obj, [entity._id]: entity }), {}) });
  },
});

const action = () => (dispatch) => dispatch(actions.callAPI(NAMESPACE, api.devices.loadList(), null, handlers));

export { action, TYPES };
