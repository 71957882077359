import React from 'react';
import { Route } from 'react-router-dom';

import List from './list';
import Device from './types';

const Component = ({ id }) => (
  <div className="m-0 p-0">
    <div className="device-list border-right">
      <List />
    </div>
    <div className="device-view p-3">
      <Route path="/devices/:id"><Device id={id} view="View" /></Route>
    </div>
  </div>
);

export default Component;
