import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';

import reducers from '../state';
import apiMiddleware from './api';

const store = createStore(reducers, applyMiddleware(
  apiMiddleware,
  thunkMiddleware
));
const persistor = persistStore(store);

export default {
  store,
  persistor,
};
