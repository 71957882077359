import config from '../config';
import reducer from '../utils/reducer';
import { app } from '../lib';

const initialState = { code: config.LANG };

const handlers = {
  [app.setLanguage.TYPE]: (state, payload) => ({ code: payload }),
};

export default reducer(handlers, initialState);
