import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import View from './view';
import { user } from '../../../../lib';
import { api as endpoints } from '../../../../utils/api';
import schema from './schema';
import { useForm } from '../../../../utils/hooks/useForm';
import { useApiRequest } from '../../../../utils/hooks';

const Component = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { state, resetState, execute } = useApiRequest();
  const form = useForm({ username: '', password: '' }, schema);

  useEffect(() => {
    if (state.success && state.data) {
      dispatch(user.login.action({ id: state.data.id, jwt: state.data.jwt }));
    }
  }, [state, dispatch]);

  const handlers = {
    onSubmit: () => execute(endpoints.users.auth.login(), form.toJSON()),
    onReset: resetState,
  };

  return <View form={form} state={state} handlers={handlers} t={t} />;
};

export default Component;
