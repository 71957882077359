import React from 'react';
import { NavLink } from 'react-router-dom';

import { Battery, Wifi } from '../../../../../../components';

const View = ({ device }) => (
  <NavLink to={`/devices/${device._id}/view`} className="no-hover">
    <div className="card card-stats mb-0">
      <div className="card-header bg-custom text-light py-1">
        <div className="row justify-content-between px-1">
          <div>{device.name}</div>
          <div className="text-nowrap">
            {device.data.acum && <Battery level={device.data.acum} />}
            {device.data.wifi && <Wifi level={device.data.wifi} connected={device.connected} />}
          </div>
        </div>
      </div>
      <div className="card-body py-1">
        <div className="row justify-content-between align-items-center px-2">
          <h4 className="device-icon-wrapper device-circle text-info d-flex align-items-center justify-content-center m-0">
            <i className="nc-icon nc-atom fa-spin"/>
          </h4>
          <div className="text-danger mr-3">Unknown device type</div>
        </div>
      </div>
    </div>
  </NavLink>
);

export default View;
