import React from 'react';
import { useTranslation } from 'react-i18next';

import View from './view';

const Component = ({ props }) => {
  const { t } = useTranslation();

  return <View device={props} t={t} />;
};

export default Component;
