import reducer from '../utils/reducer';
import { user } from '../lib';

const initialState = {};

const handlers = {
  [user.getData.TYPES.SUCCESS]: (state, payload) => payload,
  [user.getData.TYPES.FAILURE]: () => initialState,

  [user.login.TYPE]: (state, payload) => payload,
  [user.logout.TYPE]: () => initialState,

  [user.update.TYPE]: (state, payload) => ({ ...state, ...payload }),
};

export default reducer(handlers, initialState);
