import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Logs from './logs';
import Settings from './settings';

const View = ({ id }) => (
  <div className="col-12 p-2 bg-light">
    <Switch>
      <Route path="/devices/:id/settings"><Settings id={id} /></Route>
      <Route path="/devices/:id/view"><Logs id={id} /></Route>
      <Redirect to={`/devices/${id}/view`} />
    </Switch>
  </div>
);

export default View;
