import React from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';

import List from './list';
import Device from './types';

const Component = ({ id }) => (
  <div className="m-0 p-0">
    <Switch>
      <Route path="/devices/:id">
        <div>
          <NavLink to="/devices">
            <div className="text-danger p-2 ml-2">
              <i className="fa fa-md fa-arrow-circle-o-left"/>
              <span className="ml-2"><b>Atgal</b></span>
            </div>
          </NavLink>
        </div>
        <div className="m-0">
          <Device id={id} view="View" />
        </div>
      </Route>
      <Route path="/devices"><List /></Route>
      <Redirect to="/devices" />
    </Switch>
  </div>
);

export default Component;
