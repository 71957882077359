import React from 'react';

import { Form } from '../../../../../../../components';
import { MEASUREMENT_TYPE } from '../../../../../../../../lib/devices/distance';
import { FormFieldInput } from '../../../../../../../components/Form/formField';

const MEASUREMENT_TYPE_OPTION = {
  [MEASUREMENT_TYPE.FROM_TOP]: 'Užpildymas nuo viršaus (cm)',
  [MEASUREMENT_TYPE.FROM_BOTTOM]: 'Užpildymas nuo dugno (cm)',
  [MEASUREMENT_TYPE.PERCENT]: 'Procentinis talpos užpildymas (%)',
};

const MEASUREMENT_TYPE_INFO_LBL = {
  [MEASUREMENT_TYPE.FROM_TOP]: 'Atstumas nuo talpos viršaus iki skysčio (ar kitų medžiagų) pavišiaus.',
  [MEASUREMENT_TYPE.FROM_BOTTOM]: 'Atstumas nuo talpos dugno iki skysčio (ar kitų medžiagų) pavišiaus.',
  [MEASUREMENT_TYPE.PERCENT]: 'Talpos tūrio užpildymas procentais. 0% - talpa tuščia, 100% - talpa pilna.',
};

const Component = ({ form, apiState, handlers }) => (
  <div className="card">
    <div className="card-header">
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="card-title">Prietaiso nustatymai</h5>
      </div>
    </div>
    <Form.Progress on={apiState.pending} />
    <Form.Messages form={form} state={apiState} onClose={handlers.onReset} successMessage='Nustatymai sėkmingai išsaugoti.' />
    <div className="card-body">
      <form onSubmit={handlers.onSubmit} onChange={handlers.onReset}>
        <div className="row">
          <div className="col-md-6 pr-md-2">
            <div className="card px-0 border">
              <div className="card-header border-bottom">
                <h5 className="card-title mt-0">Matavimai</h5>
              </div>
              <div className="card-body">
                <label>Matavimo būdas</label>
                <div className="form-group">
                  <select {...form.field('measurementType')} className="form-control">
                    {Object.entries(MEASUREMENT_TYPE_OPTION).map(([key, value]) => (
                      <option key={key} value={key}>{value}</option>
                    ))}
                  </select>
                </div>
                <div className='text-xsm mb-3'>
                  <i className="fa fa-info-circle mr-2 text-info"></i>
                  {MEASUREMENT_TYPE_INFO_LBL[parseInt(form.state.fields.measurementType.value)]}
                </div>
                {(parseInt(form.state.fields.measurementType.value) === MEASUREMENT_TYPE.PERCENT) ? (
                  <>
                    <FormFieldInput form={form} field="minHeight" label="Mažiausias atstumas (cm)" maxLength={5} placeholder="cm" />
                    <div className='text-xsm mb-3'>
                      <i className="fa fa-info-circle mr-2 text-info"></i>
                      Atstumas nuo viršaus 100% užpildymui paskaičiuoti.
                    </div>
                  </>
                ) : ''}
                <FormFieldInput form={form} field="containerHeight" label="Atstumas iki talpos dugno (cm)" maxLength={5} placeholder="cm" />
                <div className='text-xsm mb-3'>
                  <i className="fa fa-info-circle mr-2 text-info"></i>
                  Atstumas nuo jutiklio iki talpos dugno.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 pl-md-2">
            <div className="card px-0 border">
              <div className="card-header border-bottom">
                <h5 className="card-title mt-0">Pranešimų siuntimas</h5>
              </div>
              <div className="card-body">
                {(parseInt(form.state.fields.measurementType.value) === MEASUREMENT_TYPE.FROM_TOP) ? (
                  <>
                    <FormFieldInput form={form} field="minDistanceTop" label="Min riba (cm)" maxLength={5} placeholder="min (cm)" />
                    <FormFieldInput form={form} field="maxDistanceTop" label="Max riba (cm)" maxLength={5} placeholder="max (cm)" />
                  </>
                ) : (parseInt(form.state.fields.measurementType.value) === MEASUREMENT_TYPE.FROM_BOTTOM) ? (
                  <>
                    <FormFieldInput form={form} field="minDistanceBottom" label="Min riba (cm)" maxLength={5} placeholder="min (cm)" />
                    <FormFieldInput form={form} field="maxDistanceBottom" label="Max riba (cm)" maxLength={5} placeholder="max (cm)" />
                  </>
                ) : (
                  <>
                    <FormFieldInput form={form} field="minPercent" label="Min riba (%)" maxLength={5} placeholder="min (%)" />
                    <FormFieldInput form={form} field="maxPercent" label="Max riba (%)" maxLength={5} placeholder="max (%)" />
                  </>
                )}
                <div className="form-check mt-3">
                  <label className="form-check-label">
                    <input {...form.field('sendEmail')} className="form-check-input" type="checkbox" />
                    <span className="form-check-sign"></span>
                    <span className="text-sm text-dark">Siųsti pranešimus</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div className="card-footer border-top">
      <Form.Submit form={form} state={apiState} label="Išsaugoti" onSubmit={handlers.onSubmit} />
    </div>
  </div>
);

export default Component;
