import React from 'react';

const Component = ({ level, dark = false }) => {
  const colors = dark ? {
    default: '#ef8157',
  } : {
    default: '#f8f9fa',
  };

  return (
    <span className="m-1">
    <svg id="battery" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 377.021 377.021" width="18px" height="18px">
      <g>
        <path style={{ fill: (!level || level < 10) ? colors.default : colors.default }} d="M279.522,36.163h-22.087V20c0-11.028-8.972-20-20-20h-97.85c-11.028,0-20,8.972-20,20v16.163H97.499
      c-16.542,0-30,13.458-30,30v280.858c0,16.542,13.458,30,30,30h182.023c16.542,0,30-13.458,30-30V66.163
      C309.522,49.621,296.064,36.163,279.522,36.163z M97.499,347.021V66.163h182.023l0.003,280.858H97.499z"/>
        {(level > 90) && <rect style={{ fill: colors.default }} x="122" y="90" width="130" height="40"/>}
        {(level > 70) && <rect style={{ fill: colors.default }} x="122" y="153" width="130" height="40"/>}
        {(level > 50) && <rect style={{ fill: colors.default }} x="122" y="215" width="130" height="40"/>}
        {(level > 10) && <rect style={{ fill: (level < 20) ? colors.default : colors.default }} x="122" y="277" width="130" height="40"/>}
        {(!level || level < 10) && <polygon style={{ fill: colors.default }}
                                            points="223.419,136.658 183.511,176.566 143.603,136.658 115.8,164.461 155.708,204.369 115.8,244.277 143.603,272.081
          183.511,232.172 223.419,272.081 251.222,244.277 211.313,204.369 251.222,164.461"/>}
      </g>
    </svg>
  </span>
  );
};

export default Component;
