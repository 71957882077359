import mqtt from 'mqtt';
import { isEmpty } from 'ramda';

import config from '../../config';

// TODO: review/refactor all this...

let mqttClient = null;
let subscriptions = {};

const connect = () => {
  mqttClient = mqtt.connect(
    `${config.MQTT_ROOT}`,
    { clientId: `hmi-app-${crypto.randomUUID().split('-')[0]}`, username: 'username', password: 'password' },
  );

  mqttClient.on('connect', () => {
    Object.keys(subscriptions).map(topic => mqttClient.subscribe(topic));
  });

  mqttClient.on('message', (topic, message) => {
    if (subscriptions[topic]) {
      Object.values(subscriptions[topic]).forEach(fn => fn(message.toString()));
    }
  });
};

const subscribe = (topic, cb) => {
  subscriptions = { ...subscriptions, [topic]: subscriptions[topic]
      ? { ...subscriptions[topic], [cb.key]: cb.fn }
      : { [cb.key]: cb.fn } };
  if (!mqttClient) {
    connect();
  } else if (mqttClient.connected) {
    mqttClient.subscribe(topic);
  }
};

const unsubscribe = (topic, cb) => {
  if (mqttClient && mqttClient.connected) {
    if (subscriptions[topic] && subscriptions[topic][cb.key]) {
      delete subscriptions[topic][cb.key];
    }
    if (isEmpty(subscriptions[topic])) {
      delete subscriptions[topic];
      mqttClient.unsubscribe(topic, () => {});
    }
  }
};

const publish = (topic, message) => {
  if (mqttClient.connected) {
    mqttClient.publish(topic, JSON.stringify(message));
  } else {
    console.log('MQTT not connected');
  }
};

export { subscribe, unsubscribe, publish };
