const WARNING_PERCENT_DELTA = 3;

const MEASUREMENT_TYPE = {
  FROM_TOP: 1,
  FROM_BOTTOM: 2,
  PERCENT: 3,
};

const VALUE_BY_MEASUREMENT_TYPE_FN = {
  [MEASUREMENT_TYPE.FROM_TOP]: (value) => value / 10,
  [MEASUREMENT_TYPE.FROM_BOTTOM]: (value, containerHeight) => containerHeight - value / 10,
  [MEASUREMENT_TYPE.PERCENT]: (value, containerHeight, minHeight) => {
    const adjustedValue = value / 10 - minHeight;
    const height = containerHeight - minHeight;
    return (height !== 0) ? (height - adjustedValue) / height * 100 : 100;
  },
};

const MIN_MAX_BY_MEASUREMENT_FN = {
  [MEASUREMENT_TYPE.FROM_TOP]: ({ minDistanceTop: min, maxDistanceTop: max }) => ({ min, max }),
  [MEASUREMENT_TYPE.FROM_BOTTOM]: ({ minDistanceBottom: min, maxDistanceBottom: max }) => ({ min, max }),
  [MEASUREMENT_TYPE.PERCENT]: ({ minPercent: min, maxPercent: max }) => ({ min, max }),
};

const getDistanceDeviceConfigWithDefaults = (config) => ({
  measurementType: config.measurementType || MEASUREMENT_TYPE.FROM_TOP,
  minHeight: config.minHeight || 0,
  containerHeight: config.containerHeight || 0,
  minDistanceTop: config.minDistanceTop || '',
  maxDistanceTop: config.maxDistanceTop || '',
  minDistanceBottom: config.minDistanceBottom || '',
  maxDistanceBottom: config.maxDistanceBottom || '',
  minPercent: config.minPercent || '',
  maxPercent: config.maxPercent || '',
  sendEmail: config.sendEmail || false,
});

const appendDefaultConfig = (rawConfig) => {
  const config = rawConfig || {};
  config.measurementType = config.measurementType || MEASUREMENT_TYPE.FROM_TOP;
  config.minHeight = config.minHeight || 0;
  config.containerHeight = config.containerHeight || 0;

  return config;
};

const getDataByConfig = (rawValue, config) => {
  const { measurementType, minHeight, containerHeight } = appendDefaultConfig(config);
  const result = {
    measurementType,
    minHeight,
    containerHeight,
    value: null,
    min: null,
    max: null,
    warning: false,
    danger: false,
  };

  if (rawValue == null) {
    return result;
  }

  const { min, max } = MIN_MAX_BY_MEASUREMENT_FN[measurementType](config);
  result.raw = VALUE_BY_MEASUREMENT_TYPE_FN[MEASUREMENT_TYPE.FROM_TOP](rawValue);
  result.value = VALUE_BY_MEASUREMENT_TYPE_FN[measurementType](rawValue, containerHeight, minHeight);
  result.min = !isNaN(parseFloat(min)) ? parseFloat(min) : null;
  result.max = !isNaN(parseFloat(max)) ? parseFloat(max) : null;

  const valueWarningDelta = containerHeight * 0.05;
  const warningDelta = (measurementType === MEASUREMENT_TYPE.PERCENT) ? WARNING_PERCENT_DELTA : valueWarningDelta;
  result.danger = ((result.min != null) && (result.value <= result.min))
    || ((result.max != null) && (result.value >= result.max));
  result.warning = ((result.min != null) && (result.value - warningDelta <= result.min))
    || ((result.max != null) && (result.value + warningDelta >= result.max));

  return result;
};

export {
  MEASUREMENT_TYPE,
  VALUE_BY_MEASUREMENT_TYPE_FN,
  getDistanceDeviceConfigWithDefaults,
  appendDefaultConfig,
  getDataByConfig,
}
